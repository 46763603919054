var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "pa-0 ma-0" },
        [
          _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "12" } }, [
            _c("p", { staticClass: "f14 text-danger text-justify my-4" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "In critical situations such as any child abuse, spouse abuse or thoughts Suicide without patience Call 123(iran) And 911 (USA) Suicide Emergency"
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-row",
        { staticClass: "pa-0 ma-0" },
        [
          _c("v-col", { staticClass: "pa-0 ma-0", attrs: { cols: "12" } }, [
            _c(
              "p",
              {
                staticClass: "text-justify my-4",
                staticStyle: { "font-size": "14px" },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("USER_APPOINTMENT_REQUEST_PARAGRAPH")) +
                    " "
                ),
              ]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }