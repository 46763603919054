<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-spacer></v-spacer>
      <v-toolbar-title>
        {{ $t("APPOINTMENTS_LIST") }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <type-selection
          ref="typeSelectionRef"
          v-bind:consultantAmount="consultantAmount"
          v-if="step === 'TYPE'"
      />
      <time-selection
          @interface="pickedTime"
          :Profile="Profile"
          v-if="step === 'TIME'"
          v-bind:appointmentTypeSelected="type"
      />
      <time-confirmation
          @interface="confirmedTime"
          ref="timeConfirmationRef"
          v-bind:appointmentTypeSelected="type"
          v-bind:consultantAmount="consultantAmount"
          v-bind:timeSelectedId="timeSelected"
          v-bind:consultantPaymentType="Profile.consultantPaymentType"
          v-if="step === 'CONFIRMATION'"
      />
      <time-request v-if="step === 'REQUEST'"/>
    </v-card-text>
    <v-card-actions>
      <v-row class="ma-3">
        <v-col cols="8" v-if="step === 'TYPE'">
          <v-btn
              x-large
              color="primary"
              class="w-100 mt-4 text-center f15 white--text"
              @click="nextStep()"
          >
            {{ $t("USER_APPOINTMENT_NEXT") }}
          </v-btn>
        </v-col>
        <v-col cols="8" v-if="step === 'TIME'">
          <v-btn
              x-large
              color="secondary"
              class="w-100 mt-4 text-center f15 white--text"
              @click="previousStep()"
          >
            {{ $t("USER_APPOINTMENT_PREVIOUS") }}
          </v-btn>
        </v-col>
        <v-col cols="8" v-if="step === 'REQUEST'">
          <v-btn
              x-large
              color="secondary"
              class="w-100 mt-4 text-center f15 white--text"
              @click="requestContact()"
          >
            {{ $t("USER_APPOINTMENT_REQUEST_REGISTER") }}
          </v-btn>
        </v-col>
        <v-col cols="8" v-if="step === 'CONFIRMATION'">
          <v-dialog v-model="confirmed_by_user" max-width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  x-large
                  v-on="on" v-bind="attrs"
                  color="success"
                  class="w-100 mt-4 text-center f15 white--text"
                  @click="confirmed_by_user = true"
              >
                {{ $t("Submit") }}
              </v-btn>
            </template>

            <v-card class="text-center pa-4">
              <v-card-text class="f15 p-2 font-weight-light">
                <p>
              <span>
                در تاریخ
                <span class="text-success">
                 {{ appointmentTimeSelected }}
                </span>
                  با
                <span class="text-success">
                 {{ Profile.firstname }} {{ Profile.lastname }}
                </span>
                بصورت
                 <span class="text-success">
                   {{ $t(type) }}
                 </span>
                نوبت دارید. آیا قابل تایید است؟
              </span>
                </p>
              </v-card-text>

              <v-card-actions>
                <v-btn
                    x-large
                    color="success"
                    class="w-75 mt-4 text-center f15 white--text"
                    @click="confirmAppointment()"
                >
                  {{ $t("Submit") }}
                </v-btn>
                <v-btn
                    x-large
                    depressed
                    outlined
                    class="w-25 mt-4 text-center f15 black--text"
                    @click="confirmed_by_user = false"
                >
                  {{ $t("USER_APPOINTMENT_CANCEL") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4">
          <v-btn
              x-large
              depressed
              outlined
              class="w-100 mt-4 text-center f15 black--text"
              @click="cancelAppointment()"
          >
            {{ $t("USER_APPOINTMENT_CANCEL") }}
          </v-btn>
        </v-col>
      </v-row>

    </v-card-actions>
  </v-card>
</template>
<script>
import apiService from "@/http/ApiService.js";
import AppointmentTypeSelection from "./dialogs/AppointmentTypeSelection.vue";
import AppointmentTimeSelection from "./dialogs/AppointmentTimeSelection.vue";
import AppointmentConfirmation from "./dialogs/AppointmentConfirmation.vue";
import AppointmentRequest from "./dialogs/AppointmentRequest.vue";
import moment from "moment-jalaali";

export default {
  name: "appointment-dialog",
  props: {
    consultantAmount: {type: Number, required: true},
    Profile: {type: Object, required: true},
  },
  components: {
    "type-selection": AppointmentTypeSelection,
    "time-selection": AppointmentTimeSelection,
    "time-confirmation": AppointmentConfirmation,
    "time-request": AppointmentRequest,
  },
  data() {
    return {
      isMobileCheck: true,
      step: "TYPE",
      type: null,
      timeSelected: null,
      confirmed_by_user: null,
      appointmentTimeSelected: null,
      // description: null,
    };
  },
  methods: {
    restData() {
      this.step = "TYPE";
      this.type = null;
      this.timeSelected = null;
      this.descriptionDetail = null;
      this.appointmentTimeSelected = null;
    },
    nextStep() {
      if (this.step === "TYPE") {
        this.type = this.$refs.typeSelectionRef.$data.sessionType;
        if (this.type === "TELEPHONE") {
          this.step = "REQUEST";
        } else {
          this.step = "TIME";
        }
      } else if (this.step === "TIME") {
        this.step = "CONFIRMATION";
      } else if (this.step === "CONFIRMATION") {
        this.descriptionDetail = this.$refs.timeConfirmationRef.$data.description
        this.appointmentTimeSelected = this.$refs.timeConfirmationRef.$data.appointmentTime
      }
    },
    previousStep() {
      this.step = "TYPE";
    },
    cancelAppointment() {
      this.confirmed_by_user = false
      this.restData();
      this.$emit("interface");
    },
    confirmAppointment() {
      this.confirmed_by_user = false
      apiService
          .registerAppointment({
            doctorTimeSheetDetailId: this.timeSelected,
            sessionType: this.type,
            description: this.$refs.timeConfirmationRef.$data.description || this.descriptionDetail,
          })
          .then((res) => {
            console.info(res.data);
            this.$swal({
              icon: "info",
              text: this.$t("TIME_IS_RESERVED_SUCCESSFULLY"),
              confirmButtonText: this.$t("OK"),
            });
            this.$emit("interface");
          })
          .catch((error) => {
            console.error(error);
            this.$swal({
              icon: "error",
              text: error ? error.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t("OK"),
            });
          });
    },
    pickedTime(timeId) {
      this.timeSelected = timeId;
      this.nextStep();
    },
    confirmedTime(time) {
      this.appointmentTimeSelected = time;
    },
    requestContact() {
      console.info("Request appointment");
      this.cancelAppointment();
    },
  },
};
</script>