<template>
  <v-container class="py-4">
    <v-row>
      <v-col cols="12">
        <v-text-field
          readonly
          outlined
          type="text"
          hide-details="auto"
          class="text-center"
          v-mask="currencyMask"
          :suffix="$t('RIAL')"
          :label="$t('USER_APPOINTMENT_PER_HOUR')"
          v-model="drConsultantAmount"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <p>{{ $t("APPOINTMENT_TYPE") }}</p>
        <v-radio-group v-model="sessionType">
          <v-radio value="ON_SITE">
            <template v-slot:label>
              <div>
                {{ $t("ON_SITE") }}
              </div>
            </template>
          </v-radio>
          <v-radio value="ONLINE">
            <template v-slot:label>
              <div>
                {{ $t("ONLINE") }}
              </div>
            </template>
          </v-radio>
<!--          <v-radio value="TELEPHONE">-->
<!--            <template v-slot:label>-->
<!--              <div>-->
<!--                {{ $t("Telephone") }}-->
<!--              </div>-->
<!--            </template>-->
<!--          </v-radio>-->
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: "appointment-type-selection",
  props: {
    consultantAmount: { type: Number, required: true },
  },
  data() {
    return {
      currencyMask,
      sessionType: "ON_SITE",
      drConsultantAmount: this.consultantAmount,
    };
  },
  methods: {
    selectAppointmentTypeDialog() {},
  },
  beforeMount() {},
};
</script>