var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "py-4" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: _vm.currencyMask,
                    expression: "currencyMask",
                  },
                ],
                staticClass: "text-center",
                attrs: {
                  readonly: "",
                  outlined: "",
                  type: "text",
                  "hide-details": "auto",
                  suffix: _vm.$t("RIAL"),
                  label: _vm.$t("USER_APPOINTMENT_PER_HOUR"),
                },
                model: {
                  value: _vm.drConsultantAmount,
                  callback: function ($$v) {
                    _vm.drConsultantAmount = $$v
                  },
                  expression: "drConsultantAmount",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("APPOINTMENT_TYPE")))]),
              _c(
                "v-radio-group",
                {
                  model: {
                    value: _vm.sessionType,
                    callback: function ($$v) {
                      _vm.sessionType = $$v
                    },
                    expression: "sessionType",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { value: "ON_SITE" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", [
                              _vm._v(" " + _vm._s(_vm.$t("ON_SITE")) + " "),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("v-radio", {
                    attrs: { value: "ONLINE" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", [
                              _vm._v(" " + _vm._s(_vm.$t("ONLINE")) + " "),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }